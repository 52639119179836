<template>
  <div>
    <div v-for="(attribute,name_attribute) in attributes" :key="name_attribute" class="widget widget-category mgbt-24 boder-bt bd-collectionpage-attribute-name">
      <div class="title-wg-category" @click="filter_visibility(name_attribute)">
        <h6 class="title-widget style-2" style="font-weight: 300;">{{name_attribute}}</h6>
        <i class="fas fa-chevron-down" v-if="filters_shown.includes(name_attribute)"></i>
        <i class="fas fa-chevron-up" v-else></i>
      </div>
      <div class="content-wg-category" v-show="filter_activated(name_attribute)">
        <form action="#">
          <div style="width:100%" v-for="(parameters,index_value) in attribute" :key="index_value" v-show="filter_activated(name_attribute,parameters.value)">
            <label>{{parameters.value}}
              <input type="checkbox" :checked="filter_checked(name_attribute,parameters.value)" @change="add_filter(name_attribute,parameters.value)">
              <span class="btn-checkbox"></span>
            </label>
            <label style="float:right;">{{parameters.rarity * 100 | reduce_number(2) }}%</label>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterAttributes",
  props: {
      attributes : {},
      filters : {},
  },
  data: function(){
    return {
      filters_shown: [],
    }
  },
  methods:{
    filter_visibility: function(name_attribute){

      if(this.filters_shown.includes(name_attribute))
        this.filters_shown.splice(this.filters_shown.indexOf(name_attribute),1);
      else
        this.filters_shown.push(name_attribute);
    },
    add_filter: function(name_attribute,value){

      var filter = {'attribute':name_attribute,'value':value};

      var match_filter = false;

      for (const [key, filter_activate] of Object.entries(this.filters.attributes)) {
        if(filter.attribute === filter_activate.attribute && filter_activate.value === filter.value)
          match_filter = key;
      }

      if(match_filter)
        this.filters.attributes.splice(match_filter,1);
      else
        this.filters.attributes.push(filter);

      this.$parent.reload_list();
    },

    //Useful to know if a filter or a group of filter have to be shown
    filter_activated: function(name_attribute,value = null){

        if(this.filters_shown.includes(name_attribute))
          return true;

        for (const [key, filter_activate] of Object.entries(this.filters.attributes)) {
          if(filter_activate.attribute === name_attribute && (value == null || filter_activate.value == value))
            return true;
        }

        return false;
    },

    filter_checked: function(name_attribute,value = null){

        for (const [key, filter_activate] of Object.entries(this.filters.attributes)) {
          if(filter_activate.attribute === name_attribute && (value == null || filter_activate.value == value))
            return true;
        }

        return false;
    },
  },
}
</script>