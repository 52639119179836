<template>
	<div>
		<!-- title page -->
		<section class="flat-title-page inner bd-collectionpage-header">
			<div class="themesflat-container">
				<div class="row">
					<div class="col-xl-2 col-lg-2 col-md-12 bd-collectionpage-logo">
						<img :src="route_bo + '/storage/' + collection.logo" />
						<h4>{{ collection.name }}</h4>
						<span class="bd-button-filter-collection-display bd-button-like-collection bd-desktop-inline" style="cursor: pointer;">
							<i @click="like_collection()" :class="'fas fa-heart bd-button-icon '+(collection_liked_by_user == true ? 'active' : '')" style="font-size: 16px;"></i>
						</span>
					</div>
					<div class="col-xl-8 col-lg-8 col-md-12 bd-collectionpage-kpis-container">
						<div class="row">
							<div class="col-xl-2 col-lg-2 col-6 bd-collectionpage-kpis">
								<div>
									<span class="bd-kpi-value">◎ {{ volume | format_number }}</span><br/><br>
									<span class="bd-kpi-name">Volume</span>
								</div>
							</div>
							<div class="col-xl-2 col-lg-2 col-6 bd-collectionpage-kpis">
								<div>
									<span class="bd-kpi-value">◎ {{ floor_price | reduce_number(2,'down') }}</span><br/><br>
									<span class="bd-kpi-name">Floor</span>
								</div>
							</div>
<!--							<div class="col-xl-2 col-lg-2 col-md-12 bd-collectionpage-kpis">-->
<!--								<div>-->
<!--									<span class="bd-kpi-value">{{ listed }}</span><br/><br>-->
<!--									<span class="bd-kpi-name">Listed</span>-->
<!--								</div>-->
<!--							</div>-->
							<div class="col-xl-2 col-lg-2 col-6 bd-collectionpage-kpis">
								<div>
									<span class="bd-kpi-value">{{ collection.supply | format_number }}</span><br/><br>
									<span class="bd-kpi-name">Supply</span>
								</div>
							</div>
							<div class="col-xl-2 col-lg-2 col-6 bd-collectionpage-kpis">
								<div>
									<span class="bd-kpi-value">◎ {{ mint_price | reduce_number(2,'down') }}</span><br/><br>
									<span class="bd-kpi-name">Mint price</span>
								</div>
							</div>
							<div class="col-xl-2 col-lg-2 col-6 bd-collectionpage-kpis">
								<div>
									<span class="bd-kpi-value">{{ mint_date | moment("MMM Do YY") }}</span><br/><br>
									<span class="bd-kpi-name">Mint date</span>
								</div>
							</div>
							<div class="col-xl-2 col-lg-2 col-6 bd-collectionpage-kpis">
								<div>
									<span class="bd-kpi-value">{{ holders | format_number}}</span><br/><br>
									<span class="bd-kpi-name">Owners</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-2 col-lg-2 col-md-12 bd-collectionpage-tags" style="position: relative;">
						<div class="bd-collectionpage-social-link widget-social style-1">
							<ul>
								<li v-if="collection.twitter != null && collection.twitter != ''"><a :href="collection.twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
								<li v-if="collection.website != null && collection.website != ''"><a :href="collection.website"  target="_blank"><i class="fas fa-globe"></i></a></li>
								<li v-if="collection.discord != null && collection.discord != ''" class="mgr-none"><a :href="collection.discord"  target="_blank"><i class="icon-fl-vt"></i></a></li>
							</ul>
						</div>
						<div class="bd-collectionpage-tag">
							<router-link :to="'/all-collections/'+tag.id" v-for="(tag, key) in tags.slice(0,2)" :key="key">
								<span class="badge badge-default">{{ tag.name }}</span>
							</router-link>
							<!-- <i class="fas fa-chevron-down" v-show="tags_minified == true" @click="tags_minified = false" style="color: var(--primary-color3);"></i>
							<i class="fas fa-chevron-up" v-show="tags_minified == false" @click="tags_minified = true" style="color: var(--primary-color3);"></i> -->
						</div>
					</div>
				</div>
				
			</div>
		</section>
		
		<div class="themesflat-container bd-collectionpage-main">
		
			<div class="row bd-desktop">
				<div class="col-xl-12 col-lg-12 col-md-12 bd-collectionpage-tabs bd-collectionpage-tabs-desktop">
					<div :class="{active: active_tab=='nfts'}">
						<span class="bd-collectionpage-tab bd-collectionpage-tab-desktop" :class="{active: active_tab=='nfts'}" @click="switch_tab('nfts')"><i class="fas fa-th icone-tab"></i><span>Nfts</span></span>
					</div>
					<div :class="{active: active_tab=='infos'}">
						<span class="bd-collectionpage-tab bd-collectionpage-tab-desktop" :class="{active: active_tab=='infos'}" @click="switch_tab('infos')"><i class="fas fa-eye icone-tab"></i><span>Infos</span></span>
					</div>
					<div :class="{active: active_tab=='stats'}">
						<span class="bd-collectionpage-tab bd-collectionpage-tab-desktop" :class="{active: active_tab=='stats'}" @click="switch_tab('stats')"><i class="fas fa-chart-line icone-tab"></i><span>Stats</span></span>
					</div>
					<div :class="{active: active_tab=='activity'}">
						<span class="bd-collectionpage-tab bd-collectionpage-tab-desktop" :class="{active: active_tab=='activity'}" @click="switch_tab('activity')"><i class="fas fa-funnel-dollar icone-tab" style="font-size: 25px; position: relative; top: -2px;"></i><span>Activity</span></span>
					</div>
          <div class="bd-collectionpage-tab-desktop-background">
            <div class="bd-collectionpage-tab-desktop-background-left">

              <div class="bd-collectionpage-tab-desktop-background-left-first"></div>
              <div class="bd-collectionpage-tab-desktop-background-left-second"></div>

            </div>
            <div class="bd-collectionpage-tab-desktop-background-center"></div>
            <div class="bd-collectionpage-tab-desktop-background-right">

              <div class="bd-collectionpage-tab-desktop-background-right-first"></div>
              <div class="bd-collectionpage-tab-desktop-background-right-second"></div>

            </div>
          </div>
				</div>
			</div>
		
			<div class="row bd-mobile">
				<div class="col-xl-12 col-lg-12 col-md-12 bd-collectionpage-tabs">
					<span class="bd-collectionpage-tab" :class="{active: active_tab=='nfts'}" @click="switch_tab('nfts')"><i class="fas fa-th icone-tab"></i><span>Nfts</span></span>
					<span class="bd-collectionpage-tab" :class="{active: active_tab=='infos'}" @click="switch_tab('infos')"><i class="fas fa-eye icone-tab"></i><span>Infos</span></span>
					<span class="bd-collectionpage-tab" :class="{active: active_tab=='stats'}" @click="switch_tab('stats')"><i class="fas fa-chart-line icone-tab"></i><span>Stats</span></span>
					<span class="bd-collectionpage-tab" :class="{active: active_tab=='activity'}" @click="switch_tab('activity')"><i class="fas fa-bolt icone-tab"></i><span>Activity</span></span>
				</div>
			</div>
			
			<!-- mobile options -->
			<div class="bd-mobile" style="margin-top: 70px;" v-if="active_tab == 'nfts'">
				<div style="display: flex; justify-content: space-between;">
					<span class="bd-button-square" @click="$root.rotate($event,'reload_list_nfts_collection_page');reload_list()">
						<i class="fas fa-sync-alt bd-button-icon" @click="$root.number_element_per_line_cookie('three')" style="font-size: 16px;"></i>
					</span>
					<span class="bd-button-square" :class="{active: $root.number_element_per_line == 'three'}" style="cursor: pointer;">
						<i class="fas fa-th-large bd-button-icon" @click="$root.number_element_per_line_cookie('three')" style="font-size: 16px;"></i>
					</span>
					<span class="bd-button-square" :class="{active: $root.number_element_per_line == 'four'}" style="cursor: pointer;">
						<i class="fas fa-th bd-button-icon" @click="$root.number_element_per_line_cookie('four')" style="font-size: 16px;"></i>
					</span>
					<div class="seclect-box style-2 box-right" style="width: auto !important;">
						<div id="collection_filters" class="dropdown bd-dropdown">
							<a class="btn-selector nolink bd-dropdown-link" style="min-width: 130px;">{{ display_sorting() }}</a>
							<ul>
								<li @click="filters.nfts.sorting='price.asc';reload_list();"><span>Price, low to high</span></li>
								<li @click="filters.nfts.sorting='price.desc';reload_list();"><span>Price, high to low</span></li>
								<li @click="filters.nfts.sorting='listing_date.desc';reload_list();"><span>Recently listed</span></li>
								<li @click="filters.nfts.sorting='ranking.asc';reload_list();"><span>Ranking, low to high</span></li>
								<li @click="filters.nfts.sorting='ranking.desc';reload_list();"><span>Ranking, high to low</span></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			
			<!-- mobile filters -->
			<div class="row bd-mobile"  v-if="active_tab == 'nfts'">
				<div class="col-xl-12 col-lg-12 col-md-12">
					<div class="badge bd-tag active" style="display: block; margin-top: 50px; font-weight: 500;" @click="toggle_mobile_display_filters()">
						<i class="fas fa-filter" style="margin-right: 10px;"></i>
						Filters
					</div>
				</div>
			</div>
			
		</div>
		
		
		
		<section class="tf-explore tf-section bd-collectionpage-main" v-show="active_tab == 'nfts'">
			<div class="themesflat-container">
				<div class="row">
					<div class="col-xl-3 col-lg-3 col-md-12 bd-collectionpage-filter-column" :class="{display: mobile_display_filters === true}">
						<div id="side-bar" class="side-bar style-3">
							<div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Status</h4>
								</div>
								<div class="content-wg-category">
									<form action="#">
										<div>
											<label> Listed
											<input type="checkbox" v-model="filters.nfts.status.listed" @change="reload_list">
											<span class="btn-checkbox"></span>
											</label>
										</div>
										<div>
											<label> Unlisted
											<input type="checkbox" v-model="filters.nfts.status.unlisted" @change="reload_list">
											<span class="btn-checkbox"></span>
											</label>
										</div>
									</form>
								</div>
							</div>
							<div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Price</h4>
								</div>
								<div class="content-wg-category">
									<form action="#">
										<div class="bd-slider-container">
											<Slider v-model="filters.nfts.price.value" :min="filters.nfts.price.min" :max="filters.nfts.price.max" :step=0.01 :merge="filters.nfts.price.max / 5" :format="format_filter_price" @update="reload_list"></Slider>
										</div>
									</form>
								</div>
							</div>
							<div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt" style="margin-top: 35px;">
									<h4 class="title-widget style-2">Rarity</h4>
								</div>
								<div class="content-wg-category">
									<form action="#">
										<FilterDiamonds :filters="filters.nfts"></FilterDiamonds>
									</form>
								</div>
							</div>
							<div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Attributes</h4>
								</div>
								<div class="content-wg-category">
									<form action="#">
										<FilterAttributes :attributes="collection.attributes" :filters="filters.nfts"></FilterAttributes>
									</form>
								</div>
							</div>
						</div>
					</div>

					<div class="col-xl-9 col-lg-9 col-md-12 bd-collectionpage-nft-zone bd-container-padding">
						<div class="bd-desktop" style="display: inline-flex;width: 100%; margin-bottom: 2%;">
							<div class="seclect-box style-2 box-left" style="width: 100%;">
								<span class="bd-items-count">{{count_elements}} items</span>
								<span class="bd-button-filter-collection-display-left" style="margin-left: 5%; cursor: pointer; position: relative; top: 9px;"  @click="$root.rotate($event,'reload_list_nfts_collection_page');reload_list()">
								<i class="fas fa-sync-alt bd-button-icon bd-button-icon-rotation" style="font-size: 16px;"></i>
								</span>
								<div class="search_bar_collection" style="display: none;">
									<form action="javascript:void(0);">
										<input type="text">
										<button id="button_search_bar_collection" style="cursor: default;">
										<i style="float: left;background: var(--primary-color3);padding:5px;border-radius: 50%;" class="fas fa-search"></i>
										</button>
									</form>
								</div>
							</div>
							<div class="seclect-box style-2 box-right" style="width: 100%;">
								<span class="bd-button-filter-collection-display" @click="$root.number_element_per_line_cookie('three')" :class="{active: $root.number_element_per_line == 'three'}" style="cursor: pointer;">
								<i class="fas fa-th-large bd-button-icon" @click="$root.number_element_per_line_cookie('three')" style="font-size: 16px;"></i>
								</span>
								<span class="bd-button-filter-collection-display" @click="$root.number_element_per_line_cookie('four')" :class="{active: $root.number_element_per_line == 'four'}" style="cursor: pointer;">
								<i class="fas fa-th bd-button-icon" @click="$root.number_element_per_line_cookie('four')" style="font-size: 16px;"></i>
								</span>
								<div id="collection_filters" class="dropdown bd-dropdown">
									<a class="btn-selector nolink" style="width: 250px;">{{ display_sorting() }}</a>
									<ul>
										<li @click="filters.nfts.sorting='price.asc';reload_list();"><span>Price, low to high</span></li>
										<li @click="filters.nfts.sorting='price.desc';reload_list();"><span>Price, high to low</span></li>
										<li @click="filters.nfts.sorting='listing_date.desc';reload_list();"><span>Recently listed</span></li>
										<li @click="filters.nfts.sorting='ranking.asc';reload_list();"><span>Ranking, low to high</span></li>
										<li @click="filters.nfts.sorting='ranking.desc';reload_list();"><span>Ranking, high to low</span></li>
									</ul>
								</div>
							</div>
						</div>

						<div class="box-epxlore" id="nfts-box" v-if="reload_activate !== true && elements.nfts.length === 0">
							<NoResultsNft :nft_per_line="$root.number_element_per_line"></NoResultsNft>
							<NoResultsNft2 :nft_per_line="$root.number_element_per_line"></NoResultsNft2>
							<NoResultsNft2 :nft_per_line="$root.number_element_per_line"></NoResultsNft2>
							<NoResultsNft2 :nft_per_line="$root.number_element_per_line" v-if="$root.number_element_per_line == 'four'"></NoResultsNft2>
						</div>

						<div class="box-epxlore" id="nfts-box" v-if="!reload_activate || scroll_activate">
							<NftCard :nft="nft" :nft_per_line="$root.number_element_per_line" v-for="(nft, key) in elements.nfts" :key="key"></NftCard>
							<div v-if="modulo >= 1" class="sc-card-product bd-empty-nftcard" :class="{'two-per-line': $root.number_element_per_line == 'two','three-per-line': $root.number_element_per_line == 'three', 'four-per-line': $root.number_element_per_line == 'four'}"></div>
							<div v-if="modulo >= 2" class="sc-card-product bd-empty-nftcard" :class="{'two-per-line': $root.number_element_per_line == 'two','three-per-line': $root.number_element_per_line == 'three', 'four-per-line': $root.number_element_per_line == 'four'}"></div>
							<div v-if="modulo >= 3" class="sc-card-product bd-empty-nftcard" :class="{'two-per-line': $root.number_element_per_line == 'two','three-per-line': $root.number_element_per_line == 'three', 'four-per-line': $root.number_element_per_line == 'four'}"></div>
							<div v-if="modulo >= 4" class="sc-card-product bd-empty-nftcard" :class="{'two-per-line': $root.number_element_per_line == 'two','three-per-line': $root.number_element_per_line == 'three', 'four-per-line': $root.number_element_per_line == 'four'}"></div>
						</div>
            <div class="box-epxlore" id="nfts-box" v-if="reload_activate === true">
							<NoResultsNft3 :nft_per_line="$root.number_element_per_line"></NoResultsNft3>
							<NoResultsNft3 :nft_per_line="$root.number_element_per_line"></NoResultsNft3>
							<NoResultsNft3 :nft_per_line="$root.number_element_per_line"></NoResultsNft3>
							<NoResultsNft3 :nft_per_line="$root.number_element_per_line" v-if="$root.number_element_per_line == 'four'"></NoResultsNft3>
						</div>

					</div>
				</div>
			</div>
		</section>
		
		<CollectionInfo :collection="collection" v-show="active_tab == 'infos'"></CollectionInfo>
		<CollectionStats :stats="stats" v-show="active_tab == 'stats'"></CollectionStats>

		
		<section class="tf-explore tf-section bd-collectionpage-activitytab" v-show="active_tab == 'activity'">
			<div class="themesflat-container">
				<div class="row">
					<div class="col-xl-3 col-lg-3 col-md-12 bd-collectionpage-filter-column">
						<div id="side-bar" class="side-bar style-3">
							<div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Price</h4>
								</div>
								<div class="content-wg-category  bd-slider-container">
									<form action="#">
										<div>
											<Slider v-model="filters.activity.price.value" :min="filters.activity.price.min" :max="filters.activity.price.max" :merge="filters.activity.price.max / 5" :step=0.01 :format="format_filter_price" @update="reload_list"></Slider>
										</div>
									</form>
								</div>
							</div>
							<div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Diamonds</h4>
								</div>
								<div class="content-wg-category">
									<form action="#">
										<FilterDiamonds :filters="filters.activity"></FilterDiamonds>
									</form>
								</div>
							</div>
							<div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Attributes</h4>
								</div>
								<div class="content-wg-category">
									<form action="#">
										<FilterAttributes :attributes="collection.attributes" :filters="filters.activity"></FilterAttributes>
									</form>
								</div>
							</div>
						</div>
					</div>
          <div class="col-xl-9 col-lg-9 col-md-12 bd-collectionpage-activitytab-activities bd-container-padding">
						<div style="display: inline-flex;width: 100%; margin-bottom: 2%; text-align: center;">
              <div class="seclect-box style-2 box-right" style="width: 100%;">
                <div class="badge bd-tag btn-checkbox" :class="{active: filters.activity.type.listing}" @click="filters.activity.type.listing = !filters.activity.type.listing;reload_list()">
                  Listing
                </div>
                <div class="badge bd-tag btn-checkbox" :class="{active: filters.activity.type.offers}" @click="filters.activity.type.offers = !filters.activity.type.offers;reload_list()">
                  Offers
                </div>
                <div class="badge bd-tag btn-checkbox" :class="{active: filters.activity.type.sales}" @click="filters.activity.type.sales = !filters.activity.type.sales;reload_list()">
                  Sales
                </div>
              </div>
            </div>
						<div class="row bd-collectionpage-activity-titles bd-desktop" v-if="elements.activity.length > 0">
							<div class="col-md-2">
								Activity
							</div>
							<div class="col-md-3">
								Item
							</div>
							<div class="col-md-1">
								Price
							</div>
							<div class="col-md-2">
								Ranking
							</div>
							<div class="col-md-2">
								From/To
							</div>
							<div class="col-md-2">
								Time
							</div>
						</div>
						<div class="bd-no-result"  v-if="elements.activity.length === 0">
							<h2 style="text-align:center"> No result found </h2>
						</div>
						<div id="activity-box">
							<ListActivity :activity="activity" v-for="(activity, key) in elements.activity" :key="key"></ListActivity>
						</div>
					</div>
				</div>
			</div>
		</section>
    
	</div>
</template>

<script>

import NftCard from '@/components/NftCard.vue';
import NoResultsNft from '@/components/NoResults/NoResultsNft.vue';
import NoResultsNft2 from '@/components/NoResults/NoResultsNft2.vue';
import NoResultsNft3 from '@/components/NoResults/NoResultsNft3.vue';
import CollectionInfo from '@/components/CollectionInfo.vue';
import CollectionStats from '@/components/CollectionStats.vue';

import ListActivity from '@/components/ListActivity.vue';
import FilterAttributes from '@/components/Filters/FilterAttributes.vue';
import FilterDiamonds from '@/components/Filters/FilterDiamonds.vue';
import Slider from '@vueform/slider/dist/slider.vue2.js';
import $ from 'jquery';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

export default {
  name: 'Collection',
  components: {
    NftCard,
    CollectionInfo,
    CollectionStats,
    ListActivity,
    Slider,
    FilterAttributes,
    FilterDiamonds,
    NoResultsNft,
    NoResultsNft2,
    NoResultsNft3,
    
  },
  data: function () {
	
	return {

		/* responsive */
		mobile_display_filters: false,
    nft_per_line: 'four',
		active_tab: 'nfts',
		
		// url_data: this.$route.params.id,
		elements:{
		
			nfts : {},
			activity : {},
		},
		volume: '',
		floor_price: '',
		listed: '',
		mint_date: '',
		mint_price: '',
		holders: '',
		activity_load: false,
		route_server: process.env.VUE_APP_ROOT_API,
		route_bo: process.env.VUE_APP_ROOT_BO,
		collection: {
			
			id: 0,
			description: "",
			why_you_should_buy: "",
			roadmap: [],
			name: '',
			logo: '',
			twitter: '',
			discord: '',
			website: '',
		},
    filters: this.initial_data_filter(),
		tags: [],
		tags_minified: true,
    stats: {
      top_10_sales: {},
      average_price_by_date: {},
      floor_price_by_date: {},
      volume_by_date: {},
    },
    reload_activate:false,
    scroll_activate:false,
    count_elements: 0,
    like_action_request: false,
	}
  },
  props: {
   
  },
  computed: {
		modulo: function() {
			
			var nft_per_line = 0;
			
			if(this.$root.number_element_per_line == 'four')
				nft_per_line = 4;
			else
				nft_per_line = 3;

			if(this.elements == undefined)
				return 0;
				
			if(this.elements.nfts == undefined)
				return 0;
				
			if(this.elements.nfts.length == 0)
				return 0;
				
			return parseInt(this.elements.nfts.length % nft_per_line);
			
			
		},
		collection_liked_by_user: function (){
			
			var component_vue = this;

			var liked = false;

			component_vue.$root.collections_liked_by_user.forEach(function(collection_id){

				if(collection_id === component_vue.collection.id)
					liked = true;
			});

			return liked;
		},
  },
  methods: {
  
		toggle_mobile_display_filters: function() {
			
			if(this.mobile_display_filters === true)
				this.mobile_display_filters = false;
			else
				this.mobile_display_filters = true;
		},

		like_collection: function (){

      var component_vue = this;

      if(component_vue.$root.connected == 0)
        component_vue.$root.$emit('show_modal',['connect_wallet',true]);

      else
        component_vue.request_like_nft();

    },

    request_like_nft: function(){

			var component_vue = this;

			if(component_vue.collection_liked_by_user === true){
		
				component_vue.$root.collections_liked_by_user.forEach(function(collection_id, key){

					if(collection_id === component_vue.collection.id){
						
						component_vue.collection.liked -= 1;
						component_vue.$root.collections_liked_by_user.splice(key, 1);
					}
				});
			}
			else{
			
				component_vue.collection.liked += 1;
				component_vue.$root.collections_liked_by_user.push(component_vue.collection.id);
			}

			var config = config_axios;

			config.collection_id = component_vue.collection.id
			config.wallet_address = component_vue.$root.wallet_address

			if(component_vue.like_action_request !== false)
				component_vue.like_action_request.abort();

			component_vue.like_action_request = new AbortController();

			axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collection/like_collection', config,{signal: component_vue.like_action_request.signal}).then(function(){

				component_vue.like_action_request = false;
		});	
  },
	
	switch_tab: function(new_tab) {
		
		this.active_tab = new_tab;

    if(new_tab == 'activity' || new_tab == 'nfts')
      this.reload_list(false,true);

    if(new_tab == 'stats')
      this.reload_stats();

	},
	
	display_sorting: function() {
	
		if(this.filters.nfts.sorting == 'price.asc')
			return 'Price, low to high';
			
		if(this.filters.nfts.sorting == 'price.desc')
			return 'Price, high to low';
			
		if(this.filters.nfts.sorting == 'listing_date.desc')
			return 'Recently listed';
			
		if(this.filters.nfts.sorting == 'ranking.asc')
			return 'Ranking, low to high';
			
		if(this.filters.nfts.sorting == 'ranking.desc')
			return 'Ranking, high to low';
	},

    reload_list: function(e,initialize = false,scroll = false){

      var $this = this;

      this.reload_activate = true;
      this.scroll_activate = scroll;

      var filters = $this.filters[$this.active_tab];

      if($this.active_tab === 'nfts')
        this.$cookie.set('filters_collection_page_nft',JSON.stringify(filters),{ expires: '10m' });

      if (initialize === true)
        filters['initialize'] = true;

      if (scroll === false)
        delete filters.number_per_page;

      filters.collection_id = this.collection.id;

      axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collection/reload_list/'+$this.active_tab,filters, config_axios).then(function(elements) {

        if(scroll === true)
          $this.elements[$this.active_tab] = $this.elements[$this.active_tab].concat(elements.data.elements);
        else {
          $this.elements[$this.active_tab] = elements.data.elements;
          filters.number_per_page = 15;
        }

        if(initialize === true) {
          filters.price = elements.data.filters.price;

          delete filters['initialize'];

        }

        $this.count_elements = elements.data.count_elements;

        $this.reload_activate = false;
        $this.scroll_activate = false;

      });

    },

    reload_stats: function(){

      var $this = this;

      axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collection/reload_stats',{collection_id : this.collection.id}, config_axios).then(function(stats) {

        $this.stats = stats.data.stats;

        if($this.stats.average_price_by_date !== undefined) {
          for (const [key_date, average_by_date] of Object.entries($this.stats.average_price_by_date)){
            if (average_by_date.labels === undefined)
              return false;

            average_by_date.labels.forEach(function (date, key){
              $this.stats.average_price_by_date[key_date].labels[key] = $this.$options.filters.moment(date, "MMM Do YY");
            });
          }
        }

        if($this.stats.floor_price_by_date !== undefined) {
          for (const [key_date, floor_by_date] of Object.entries($this.stats.floor_price_by_date)){
            if (floor_by_date.labels === undefined)
              return false;

            floor_by_date.labels.forEach(function (date, key){
              $this.stats.floor_price_by_date[key_date].labels[key] = $this.$options.filters.moment(date, "MMM Do YY");
            });
          }
        }

        if($this.stats.volume_by_date !== undefined) {
          for (const [key_date, volume_by_date] of Object.entries($this.stats.volume_by_date)){
            if (volume_by_date.labels === undefined)
              return false;

            volume_by_date.labels.forEach(function (date, key){
              $this.stats.volume_by_date[key_date].labels[key] = $this.$options.filters.moment(date, "MMM Do YY");
            });
          }
        }

      });

    },

    format_filter_price: function (value) {
      return '◎ '+Math.round((value + Number.EPSILON) * 100) / 100;
    },

    scroll () {
      window.onscroll = () => {
         var id_element = this.active_tab + '-box';

         var element = document.getElementById(id_element);

         if(element === null)
           return;

        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= element.offsetHeight

        if (bottomOfWindow && this.reload_activate === false && this.count_elements > this.filters[this.active_tab].number_per_page) {
          this.reload_list(false, false, true);
          this.filters[this.active_tab].number_per_page += 15;
        }
      }
    },
	
    load_page_data: function() {
      var $this = this;

      var id_collection = $this.$route.params.id;

      axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/nft/collection/'+id_collection, config_axios).then(function(nfts) {

        $this.collection = nfts.data.collection;

        if($this.$cookie.get('filters_collection_page_nft') !== null) {

          var filters_collection_page_nft = JSON.parse($this.$cookie.get('filters_collection_page_nft'));

          if(filters_collection_page_nft.collection_id === $this.collection.id)
            $this.filters['nfts'] = filters_collection_page_nft;

          else
            $this.$cookie.delete('filters_collection_page');

        }

        $this.volume = nfts.data.collection.volume;
        $this.floor_price = nfts.data.collection.floor_price;
        $this.listed = nfts.data.collection.listed;
        $this.mint_date = nfts.data.collection.mint_date;
        $this.mint_price = nfts.data.collection.mint_price;
        $this.holders = nfts.data.collection.holders;

        if(nfts.data.tags_of_collection !== null && nfts.data.tags_of_collection.length !== 0){

          $this.tags = [];

          for (var i = 0; i < nfts.data.tags_of_collection.length; i++) {

            var tags = JSON.parse(nfts.data.collection.tags);


            if(tags != null) {

              for (var y = 0; y < tags.length; y++) {

                // if (tags[y]['id'] == nfts.data.tags_of_collection[i]['tag_id'])
                  $this.tags.push(tags[y]);
              }
            }

          }
        }
        else {
          $this.tags = [];
        }

        $this.reload_list(false,true);
      });
    },

    initial_data_filter(){
      return {
        nfts: {
          attributes: [],
          price: {
            value: [0, 500],
            min: 0,
            max: 500,
          },
          status: {
            listed: true,
            unlisted: false,
          },
          diamonds: {
            0: false,
            1: false,
            2: false,
          },
          sorting: 'price.asc',
          number_per_page: 15,
        },
        activity: {
          attributes: [],
          price: {
            value: [0, 500],
            min: 0,
            max: 500,
          },
          type: {
            listing: true,
            offers: true,
            sales: true,
          },
          diamonds: {
            0: false,
            1: false,
            2: false,
          },
          number_per_page: 15,
        }
      }
    },
  },
	watch: { 
		'$route.params.id': {
			handler: function(search) {

        if(this.collection.id !== 0) {
          this.active_tab = 'nfts';

          this.load_page_data();
          this.filters = this.initial_data_filter();
        }
			},
			deep: true,
			immediate: true
		}
	},
  created: function() {

    this.load_page_data();

  },
  mounted () {
    window.scrollTo(0, 0)
    this.scroll();

    var component_vue = this;

    component_vue.$root.$on('change_buy_nft',function(nft_id){

      component_vue.reload_list(false,true);

		});
  },
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

