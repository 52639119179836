<template>
	<section class="tf-explore tf-section bd-collectionpage-stats">
		<div class="themesflat-container">
		
			<!-- KPIs -->
			<div class="row">
				<div class="col-md-2"></div>
				<div class="col-md-8">
					<div class="row">
						<div class="col-md-3 col-6 bd-collectionpage-stats-kpi">
							<div>
								<h5>{{ stats.total_sales | format_number }}</h5>
								<span class="bd-collectionpage-stats-kpi-name">Total sales</span>
							</div>
						</div>
						<div class="col-md-3 col-6 bd-collectionpage-stats-kpi">
							<div>
								<h5>◎ {{ stats.average_price }}</h5>
								<span class="bd-collectionpage-stats-kpi-name">Average price</span><br/>
								<span>(7 days)</span>
							</div>
						</div>
						<div class="col-md-3 col-6 bd-collectionpage-stats-kpi">
							<div>
								<h5 v-show="stats.highest_sale !== null">◎ {{ stats.highest_sale }}</h5>
								<h5 v-show="stats.highest_sale === null">None</h5>
								<span class="bd-collectionpage-stats-kpi-name">Highest sale</span><br/>
								<span>(All time)</span>
							</div>
						</div>
						<div class="col-md-3 col-6 bd-collectionpage-stats-kpi">
							<div>
								<h5 v-show="stats.higher_sale !== null">◎ {{ stats.higher_sale }}</h5>
								<h5 v-show="stats.higher_sale === null">None</h5>
								<span class="bd-collectionpage-stats-kpi-name">Highest sale</span><br/>
								<span>(7 days)</span>
							</div>
						</div>
				
					</div>
				</div>
			</div>
			
			<!-- Sales history -->
			<div class="row">
				<div class="col-md-12">
					<div class="bd-collectionpage-stats-sales-history bd-container-margin">
						<!-- desktop -->
						<div style="display: flex; justify-content: space-between" class="bd-desktop">
							<h2 class="bd-title">Sales history</h2>
							<div class="seclect-box style-2 box-right">
								<div id="collection_filters_sales" class="dropdown bd-dropdown">
									<a class="btn-selector nolink" style="width: 250px;">Last 7 days</a>
									<ul>
										<li @click="graph_filter = '7_last_days'"><span>Last 7 days</span></li>
										<li @click="graph_filter = '30_last_days'"><span>Last 30 days</span></li>
										<li @click="graph_filter = '90_last_days'"><span>Last 90 days</span></li>
										<li @click="graph_filter = 'last_year'"><span>Last year</span></li>
										<li @click="graph_filter = 'all_time'"><span>All time</span></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div class="row bd-desktop">
							<div class="col-md-12">
								<div class="badge bd-tag" :class="{active: active_graph == 'floor'}" @click="active_graph = 'floor'">Floor price</div>
								<div class="badge bd-tag" :class="{active: active_graph == 'average'}" @click="active_graph = 'average'">Average price</div>
								<div class="badge bd-tag" :class="{active: active_graph == 'volume'}" @click="active_graph = 'volume'">Volume</div>
							</div>
						</div>
						
						<!-- mobile -->
						<div class="bd-mobile center">
							<h2 class="bd-title">Sales history</h2>
							
							<div class="seclect-box style-2 box-right bd-dropdown-container-center" style="margin-top: 20px;">
								<div id="collection_filters_sales" class="dropdown bd-dropdown">
									<a class="btn-selector nolink" style="width: 250px;">Last 7 days</a>
									<ul>
										<li @click="graph_filter = '7_last_days'"><span>Last 7 days</span></li>
										<li @click="graph_filter = '30_last_days'"><span>Last 30 days</span></li>
										<li @click="graph_filter = '90_last_days'"><span>Last 90 days</span></li>
										<li @click="graph_filter = 'last_year'"><span>Last year</span></li>
										<li @click="graph_filter = 'all_time'"><span>All time</span></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div class="row bd-mobile">
							<div class="col-md-12" style="display: flex; justify-content: space-between">
								<div class="badge bd-tag" :class="{active: active_graph == 'floor'}" @click="active_graph = 'floor'">Floor</div>
								<div class="badge bd-tag" :class="{active: active_graph == 'average'}" @click="active_graph = 'average'">Average</div>
								<div class="badge bd-tag" :class="{active: active_graph == 'volume'}" @click="active_graph = 'volume'">Volume</div>
							</div>
						</div>
						
						
						
						
						
						<div class="row">
							<div class="col-md-12">
								<StatGraph class="graph_stat_info" :chart-data="floor_price_by_date_formated" :options="options" v-if="floor_price_by_date_formated !== undefined && active_graph == 'floor'"></StatGraph>
							</div>
							<div class="col-md-12">
								<StatGraph class="graph_stat_info" :chart-data="average_price_by_date_formated" :options="options" v-if="average_price_by_date_formated !== undefined && active_graph == 'average'"></StatGraph>
							</div>
              <div class="col-md-12">
								<StatGraph class="graph_stat_info" :chart-data="volume_by_date_formated" :options="options" v-if="volume_by_date_formated !== undefined && active_graph == 'volume'"></StatGraph>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			
			<!-- Top 10 sales -->
			<div class="row">
				<div class="col-md-12">
					<div class="bd-collectionpage-stats-top10 bd-container-padding">
						
						<div class="bd-collectionpage-stats-title-and-filter bd-desktop">
							<h2 class="bd-title"><span class="fa fa-fire-alt"></span> Top 10 sales</h2>
							<div class="seclect-box style-2 box-right">
								<div id="collection_filters" class="dropdown bd-dropdown">
									<a class="btn-selector nolink" style="width: 250px;">Last 7 days</a>
									<ul>
										<li @click="top_10_sales_filter = '7_last_days'"><span>Last 7 days</span></li>
										<li @click="top_10_sales_filter = '30_last_days'"><span>Last 30 days</span></li>
										<li @click="top_10_sales_filter = '90_last_days'"><span>Last 90 days</span></li>
										<li @click="top_10_sales_filter = 'last_year'"><span>Last year</span></li>
										<li @click="top_10_sales_filter = 'all_time'"><span>All time</span></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="bd-collectionpage-stats-title-and-filter display bd-mobile">
							<h2 class="bd-title"><span class="fa fa-fire-alt"></span> Top 10 sales</h2>
							<div class="seclect-box style-2 box-right bd-dropdown-container-center" style="margin-top: 20px;">
								<div id="collection_filters" class="dropdown bd-dropdown">
									<a class="btn-selector nolink" style="width: 250px;">Last 7 days</a>
									<ul>
										<li @click="top_10_sales_filter = '7_last_days'"><span>Last 7 days</span></li>
										<li @click="top_10_sales_filter = '30_last_days'"><span>Last 30 days</span></li>
										<li @click="top_10_sales_filter = '90_last_days'"><span>Last 90 days</span></li>
										<li @click="top_10_sales_filter = 'last_year'"><span>Last year</span></li>
										<li @click="top_10_sales_filter = 'all_time'"><span>All time</span></li>
									</ul>
								</div>
							</div>
						</div>
						
						<!-- desktop -->
						<div class="row bd-desktop">
							<div class="col-md-6">
								<div class="row bd-collectionpage-stats-top10-collumn-name">
									<div class="col-md-1"><b>#</b></div>
									<div class="col-md-5"><b>NFT</b></div>
									<div class="col-md-2"><b>Price</b></div>
									<div class="col-md-2"><b>Rank</b></div>
									<div class="col-md-2"><b>Date</b></div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row bd-collectionpage-stats-top10-collumn-name">
									<div class="col-md-1"><b>#</b></div>
									<div class="col-md-5"><b>NFT</b></div>
									<div class="col-md-2"><b>Price</b></div>
									<div class="col-md-2"><b>Rank</b></div>
									<div class="col-md-2"><b>Date</b></div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="bd-collectionpage-stats-top10-item" v-for="(nft, key) in top_10_sales_formated" :key="key" v-show="key <= 4">
									<div class="row">
										<div class="col-md-1"><b>{{ key + 1 }}</b></div>
										<div class="col-md-5">
											<img :src="nft.picture" style="margin-right: 15px;" />
											<router-link :to="'/nft/'+nft.mint_address"><b>{{ nft.name }}</b></router-link>
										</div>
										<div class="col-md-2">◎ {{ nft.price }}</div>
										<div class="col-md-2">{{ nft.ranking }}</div>
										<div class="col-md-2">{{ nft.date | moment("MMM Do YY") }}</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="bd-collectionpage-stats-top10-item" v-for="(nft, key) in top_10_sales_formated" :key="key" v-show="key > 4">
									<div class="row">
										<div class="col-md-1"><b>{{ key + 1 }}</b></div>
										<div class="col-md-5">
											<img :src="nft.picture" style="margin-right: 15px;" />
											<router-link :to="'/nft/'+nft.mint_address"><b>{{ nft.name }}</b></router-link>
										</div>
										<div class="col-md-2">◎ {{ nft.price }}</div>
										<div class="col-md-2">{{ nft.ranking }}</div>
										<div class="col-md-2">{{ nft.date | moment("MMM Do YY") }}</div>
									</div>
								</div>
							</div>
						</div>
						
						<!-- Mobile -->
						<div class="row bd-mobile">
							<div class="col-12">
								<div class="bd-collectionpage-stats-top10-item" v-for="(nft, key) in top_10_sales_formated" :key="key">
									<div class="row">
										<div class="col-4">
											<router-link :to="'/nft/'+nft.mint_address"><img :src="nft.picture" style="margin-right: 15px;" /></router-link>
										</div>
										<div class="col-8 bd-collectionpage-stats-top10-item-info">
											<b>{{ nft.name }}</b>
											<span>◎ {{ nft.price }}</span>
											<span>{{ nft.date | moment("MMM Do YY") }}</span>
										</div>
										
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
			
			<!-- Top 10 holders -->
			<div class="row">
				<div class="col-md-12">
					<div class="bd-collectionpage-stats-top10 bd-collectionpage-stats-top10-holders bd-container-padding">
					
					
						<div class="bd-collectionpage-stats-title-and-filter bd-desktop">
							<h2 class="bd-title"><span class="fa fa-fire-alt"></span> Top 10 holders</h2>
						</div>
						
						<div class="bd-collectionpage-stats-title-and-filter display bd-mobile">
							<h2 class="bd-title"><span class="fa fa-fire-alt"></span> Top 10 holders</h2>
						</div>
					
						<!-- desktop -->
						<div class="row bd-desktop">
							<div class="col-md-6">
								<div class="row bd-collectionpage-stats-top10-collumn-name">
									<div class="col-md-1"><b>#</b></div>
									<div class="col-md-7"><b>Wallet</b></div>
									<div class="col-md-2"><b>Owned</b></div>
									<div class="col-md-2"><b>Listed</b></div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row bd-collectionpage-stats-top10-collumn-name">
									<div class="col-md-1"><b>#</b></div>
									<div class="col-md-7"><b>Wallet</b></div>
									<div class="col-md-2"><b>Owned</b></div>
									<div class="col-md-2"><b>Listed</b></div>
								</div>
							</div>
							
							<div class="col-md-6">
								<div class="bd-collectionpage-stats-top10-item" v-for="(owner, key) in stats.top_10_holders" :key="key" v-show="key <= 4">
									<div class="row">
										<div class="col-md-1"><b>{{ key + 1 }}</b></div>
										<div class="col-md-7">
											<img v-if="owner.picture == null || owner.picture == 'null' || owner.picture == undefined" src="/assets/images/avatar/default_avatar.png" alt="Image" class="avatar" style="margin-right: 10px;">
											<img v-else :src="route_bo + '/storage/' + owner.picture" class="avatar" style="margin-right: 10px;">
											<b><router-link :to="'/user/'+owner.user_wallet">{{ owner.user_wallet | reduce_text(4,4) }}</router-link></b>
										</div>
										<div class="col-md-2"><b>{{ owner.owned }}</b></div>
										<div class="col-md-2"><b>{{ owner.listed }}</b></div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="bd-collectionpage-stats-top10-item" v-for="(owner, key) in stats.top_10_holders" :key="key" v-show="key > 4">
									<div class="row">
										<div class="col-md-1"><b>{{ key + 1 }}</b></div>
										<div class="col-md-7">
											<img v-if="owner.picture == null || owner.picture == 'null' || owner.picture == undefined" src="/assets/images/avatar/default_avatar.png" alt="Image" class="avatar" style="margin-right: 10px;">
											<img v-else :src="route_bo + '/storage/' + owner.picture" class="avatar" style="margin-right: 10px;">
											<b><router-link :to="'/user/'+owner.user_wallet">{{ owner.user_wallet | reduce_text(4,4) }}</router-link></b>
										</div>
										<div class="col-md-2"><b>{{ owner.owned }}</b></div>
										<div class="col-md-2"><b>{{ owner.listed }}</b></div>
									</div>
								</div>
							</div>
							
						</div>
						
						<!-- mobile -->
						<div class="row bd-mobile">
							<div class="col-md-6">
								<div class="bd-collectionpage-stats-top10-item bd-collectionpage-stats-top10-holder-item" v-for="(owner, key) in stats.top_10_holders" :key="key">
									<div class="row">
										<div class="col-4">
											<router-link :to="'/user/'+owner.user_wallet">
												<img v-if="owner.picture == null || owner.picture == 'null' || owner.picture == undefined" src="/assets/images/avatar/default_avatar.png" alt="Image" class="avatar" style="margin-right: 10px;">
												<img v-else :src="route_bo + '/storage/' + owner.picture" class="avatar" style="margin-right: 10px;">
											</router-link>
										</div>
										<div class="col-8 bd-collectionpage-stats-top10-item-info">
											<span><b>Owned : {{ owner.owned }}</b></span>
											<span>Listed : {{ owner.listed }}</span>
											<span>Wallet : {{ owner.user_wallet | reduce_text(3,3) }}</span>
										</div>
									</div>
								</div>
							</div>
							
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import StatGraph from '@/components/StatGraph';

const options = {
	responsive: true,
	maintainAspectRatio: false,
	animation: {
		animateRotate: false
	},
	legend: {
		display: false
	}
}

export default {
	name: 'CollectionStats',
	components: {
		StatGraph,
	},
	props: {
		stats: {},

	},
	data:function(){
		return{
			
			options,
			active_graph: 'floor',
			route_bo: process.env.VUE_APP_ROOT_BO,
      top_10_sales_filter: '7_last_days',
      graph_filter: '7_last_days',
		}
	},
	computed: {

    top_10_sales_formated: function(){

      if(this.stats.top_10_sales[this.top_10_sales_filter] === undefined)
        return [];

      return this.stats.top_10_sales[this.top_10_sales_filter];

    },

    average_price_by_date_formated: function(){

      if(this.stats.average_price_by_date[this.graph_filter] === undefined)
        return [];

      return JSON.parse(JSON.stringify(this.stats.average_price_by_date[this.graph_filter]));

    },

    floor_price_by_date_formated: function(){

      if(this.stats.floor_price_by_date[this.graph_filter] === undefined)
        return [];

      return JSON.parse(JSON.stringify(this.stats.floor_price_by_date[this.graph_filter]));
    },

    volume_by_date_formated: function(){

      if(this.stats.volume_by_date[this.graph_filter] === undefined)
        return [];

      return JSON.parse(JSON.stringify(this.stats.volume_by_date[this.graph_filter]));
    },
	},
}

</script>
