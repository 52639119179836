<template>
	<section class="tf-explore tf-section bd-collectionpage-infotab">
		<div class="themesflat-container">
			
			<!-- Description -->
			<div class="row">
				<div class="col-md-12">
					<h2 style="margin-top: 40px;">Description</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3"></div>
				<div class="col-md-6 center">
					<nl2br tag="div" :text="(collection.description != null ? collection.description : '')" class-name="bd-collectionpage-infotab-description"/>
          <i style="font-size: 20px;" v-show="collection.description === null || collection.description === '' || collection.description === undefined">No information available at this time</i>
				</div>
			</div>
			
			<!-- Roadmap -->
			<div class="row">
				<div class="col-md-12">
					<h2>Roadmap</h2>
				</div>
			</div>
			
			<div class="row">
				<div class="col-md-3"></div>
				<div class="col-md-6 center">
					<div class="row" v-for="(roadmap, key) in collection.roadmap" :key="key">
						<div class="col-md-6 bd-collectionpage-infotab-roadmap-date">
							<span class="bd-collectionpage-infotab-roadmap-title">{{ roadmap.date }}</span>
						</div>
						<div class="col-md-6 bd-collectionpage-infotab-roadmap-item" :class="{active: key ==0 }">
							<span class="bd-collectionpage-infotab-roadmap-title">{{ roadmap.title }}</span>
							<nl2br tag="span" :text="(roadmap.description != null ? roadmap.description : '')"/>
						</div>
					</div>
          <div v-show="collection.roadmap === null || Object.entries(collection.roadmap).length === 0">
            <i style="font-size: 20px;">No information available at this time</i>
          </div>
				</div>
			</div>
			
			<!-- Words from owners -->
			<div class="row">
				<div class="col-md-12">
					<h2>Words from the owners: why you should buy our NFTs</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3"></div>
				<div class="col-md-6 center">
					<nl2br tag="div" :text="(collection.why_you_should_buy != null ? collection.why_you_should_buy : '')" class-name="bd-collectionpage-infotab-description"/>
          <i style="font-size: 20px;" v-show="collection.why_you_should_buy === null || collection.why_you_should_buy === '' || collection.why_you_should_buy === undefined">No information available at this time</i>
				</div>
			</div>
		
			
		</div>
	</section>
</template>

<script>


export default {
	name: 'CollectionInfo',
	components: {

	},
	props: {
		collection: {},

	},
	data:function(){
		return{}
	},
}

</script>
